import { reactive, readonly } from "vue";
import { AuthDataKey, AuthState } from "@/store/modules/auth/";
import { OAuth2Token } from "@finapp/proto/pkg-ts/auth";
import { Employee } from "@finapp/proto/pkg-ts/common/common";
import { AclPrivileges, AclRole } from "@/shared/plugin/acl/acl.type";

export const getEmployeeDefault = (): Employee => ({
  activeDirectoryLogin: "",
  accessLevelByJobTitle: "",
  alternateEmployeeDate: "",
  alternateEmployeeEks: "",
  alternateEmployeeFio: "",
  birthDate: "",
  city: "",
  countryIsoCde: "",
  countryName: "",
  email: "",
  fiasCode: "",
  frcDtcCode: "",
  frcName: "",
  gender: "",
  finappUserLeadPortalCode: "",
  fioLeadAdm: "",
  fioLeadFunc: "",
  firstName: "",
  jobTitleAdm: "",
  jobTitleAdmId: "",
  jobTitleBuh: "",
  jobTitleBuhId: "",
  jobTitleFact: "",
  jobTitleFactId: "",
  lastName: "",
  leadEks: "",
  leadFio: "",
  patronymicName: "",
  phoneNumberInternalFromPortal: "",
  phoneNumberMobileFromPortal: "",
  photoFileName: "",
  photoSmallFileName: "",
  place: "",
  placeId: "",
  portalCode: "",
  portalCodeLeadAdm: "",
  portalCodeLeadFunc: "",
  region: "",
  regionId: "",
  sbe: "",
  sbeId: "",
  showCostPlan: false,
  privileges: {},
  ekk: "",
  ekkName: "",
  profileId: "default",
  hasDigitalSignature: false,
  organization: "",
  employeeInn: "",
  roles: [],
});

const authState = reactive<AuthState>({
  user: getEmployeeDefault(),
  role: "user",
  token: window.localStorage.getItem(AuthDataKey.Token) || "",
  refreshToken: window.localStorage.getItem(AuthDataKey.RefreshToken) || "",
  expiresAt: Number(window.localStorage.getItem(AuthDataKey.ExpiresAt)) || 0,
});

const setUser = (user: Employee) => {
  authState.user = {
    ...user,
    // TODO: Убрать эту мутацию, после добавления мульти-роли
    roles: user.privileges[AclPrivileges.RequestsCanApproval] ? [...user.roles, AclRole.Director] : user.roles,
  };
};

const setAuthData = (authDate: OAuth2Token) => {
  const expires = Number(authDate.expiresAt?.seconds || 0) * 1000;
  window.localStorage.setItem(AuthDataKey.Token, authDate.accessToken);
  window.localStorage.setItem(AuthDataKey.RefreshToken, authDate.refreshToken);
  window.localStorage.setItem(AuthDataKey.ExpiresAt, String(expires));
  authState.token = authDate.accessToken;
  authState.refreshToken = authDate.refreshToken;
  authState.expiresAt = expires;
};

const cleanAuthData = (): void => {
  window.localStorage.removeItem(AuthDataKey.Token);
  window.localStorage.removeItem(AuthDataKey.RefreshToken);
  window.localStorage.removeItem(AuthDataKey.ExpiresAt);
  setAuthData({
    accessToken: "",
    refreshToken: "",
    expiresAt: { seconds: "0", nanos: 0 },
  });
};

const setAuthError = (error: Error) => {
  authState.error = error;
};

export function useAuthState(): {
  state: AuthState;
  setUser(user: Employee): void;
  setAuthData(authDate: OAuth2Token): void;
  cleanAuthData(): void;
  setAuthError(error: Error): void;
} {
  return {
    state: readonly(authState) as Readonly<AuthState>,
    setUser,
    setAuthData,
    cleanAuthData,
    setAuthError,
  };
}
