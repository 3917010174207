import type { SetRule } from "@/shared/plugin/acl/acl.type";
import { AclPrivileges, AclRole, AclRules } from "@/shared/plugin/acl/acl.type";
import { Employee } from "@finapp/proto/pkg-ts/common/common";
import { AdvanceReport } from "@finapp/proto/pkg-ts/common/advance_report_objects";
import { AdvanceReportStatus } from "@/store/modules/advance-reports/advance-reports.types";
import { useAcl } from "vue-simple-acl/src";
import { AdvanceReportFormData } from "@/components/advance-report/advance-report-form/advance-report-form.type";

const { anyRole, can } = useAcl();

export function setAdvanceReportAclRules(setRule: SetRule) {
  setRule<(user: Employee, request: AdvanceReport) => void>(AclRules.AdvanceReportsApproveManager, (user, request) => {
    const rolePermission = anyRole([AclRole.Admin, AclRole.Support, AclRole.ApprovingAgent, AclRole.Director]);
    const chiefPermission =
      (request.chief?.portalCode === user.portalCode ||
        anyRole([AclRole.Admin, AclRole.Support]) ||
        request.selectedApprovingManager?.portalCode === user.portalCode ||
        anyRole([AclRole.Admin, AclRole.Support])) &&
      (request.status as AdvanceReportStatus) === AdvanceReportStatus.AwaitingApprovalManager;
    const selfApprovePermission =
      request.employee?.portalCode === user.portalCode &&
      can(AclPrivileges.AdvanceReportsSelfApproval) &&
      (request.status as AdvanceReportStatus) === AdvanceReportStatus.AwaitingApprovalManager;
    return (rolePermission && chiefPermission) || selfApprovePermission;
  });

  setRule<(user: Employee, request: AdvanceReport) => void>(
    AclRules.AdvanceReportsApproveAccounter,
    (user, request) => {
      return (
        anyRole([AclRole.Accountant, AclRole.Admin, AclRole.Support]) &&
        (request.status as AdvanceReportStatus) === AdvanceReportStatus.ApprovedManager
      );
    },
  );

  setRule<(user: Employee, request: AdvanceReport) => void>(AclRules.AdvanceReportsImprove, (user, request) => {
    const chiefPermission =
      request.chief?.portalCode === user.portalCode ||
      request.selectedApprovingManager?.portalCode === user.portalCode ||
      anyRole([AclRole.Admin, AclRole.Support]);
    const chiefRolePermission = anyRole([AclRole.Admin, AclRole.Support, AclRole.Director, AclRole.ApprovingAgent]);
    const chiefStatusPermission =
      (request.status as AdvanceReportStatus) === AdvanceReportStatus.AwaitingApprovalManager;

    const accountantRolePermission = anyRole([AclRole.Admin, AclRole.Support, AclRole.Accountant]);
    const accountantStatusPermission = (request.status as AdvanceReportStatus) === AdvanceReportStatus.ApprovedManager;

    return (
      (chiefRolePermission && chiefPermission && chiefStatusPermission) ||
      (accountantRolePermission && accountantStatusPermission)
    );
  });

  setRule(AclRules.AdvanceReportsEdit, (user: Employee, advanceReport: AdvanceReport) => {
    return (
      (advanceReport.employee?.portalCode === user.portalCode || anyRole([AclRole.Admin, AclRole.Support])) &&
      ((advanceReport.status as AdvanceReportStatus) === AdvanceReportStatus.NeedChange ||
        (advanceReport.status as AdvanceReportStatus) === AdvanceReportStatus.Draft ||
        (advanceReport.status as AdvanceReportStatus) === AdvanceReportStatus.RevokeForChanging)
    );
  });

  setRule(AclRules.AdvanceReportsViewSign, (user: Employee, advanceReport: AdvanceReport) => {
    const employeeRole =
      advanceReport.employee?.portalCode === user.portalCode || anyRole([AclRole.Admin, AclRole.Support]);
    const employeeStatus = [
      AdvanceReportStatus.AwaitingSignAccountant,
      AdvanceReportStatus.AwaitingDigitalSignEmployee,
      AdvanceReportStatus.AwaitingPhysicalSignEmployee,
      AdvanceReportStatus.DigitallySigned,
      AdvanceReportStatus.PhysicallySigned,
    ].includes(advanceReport.status as AdvanceReportStatus);

    const accountantRole = anyRole([AclRole.Admin, AclRole.Support, AclRole.Accountant]);
    const accountantStatus = [
      AdvanceReportStatus.AwaitingSignAccountant,
      AdvanceReportStatus.AwaitingDigitalSignEmployee,
      AdvanceReportStatus.AwaitingPhysicalSignEmployee,
      AdvanceReportStatus.DigitallySigned,
      AdvanceReportStatus.PhysicallySigned,
    ].includes(advanceReport.status as AdvanceReportStatus);

    const directorRole = anyRole([AclRole.Director, AclRole.ApprovingAgent, AclRole.Admin, AclRole.Support]);
    const directorStatus = [AdvanceReportStatus.DigitallySigned, AdvanceReportStatus.PhysicallySigned].includes(
      advanceReport.status as AdvanceReportStatus,
    );

    return (employeeRole && employeeStatus) || (directorStatus && directorRole) || (accountantStatus && accountantRole);
  });

  setRule(AclRules.AdvanceReportsDigitalSign, (user: Employee, advanceReport: AdvanceReport) => {
    const employeeRole =
      advanceReport.employee?.portalCode === user.portalCode || anyRole([AclRole.Admin, AclRole.Support]);
    const employeeStatus = [
      AdvanceReportStatus.AwaitingSignAccountant,
      AdvanceReportStatus.AwaitingDigitalSignEmployee,
    ].includes(advanceReport.status as AdvanceReportStatus);
    const employeeEDS = advanceReport.employee?.hasDigitalSignature;

    const accountantRole = anyRole([AclRole.Admin, AclRole.Support, AclRole.Accountant]);
    const accountantStatus = [AdvanceReportStatus.AwaitingSignAccountant].includes(
      advanceReport.status as AdvanceReportStatus,
    );

    return ((employeeRole && employeeStatus) || (accountantStatus && accountantRole)) && employeeEDS;
  });

  setRule(AclRules.AdvanceReportsPhysicalSign, (user: Employee, advanceReport: AdvanceReport) => {
    const status = [
      AdvanceReportStatus.AwaitingSignAccountant,
      AdvanceReportStatus.AwaitingPhysicalSignEmployee,
      AdvanceReportStatus.AwaitingDigitalSignEmployee,
    ].includes(advanceReport.status as AdvanceReportStatus);
    const role = anyRole([AclRole.Admin, AclRole.Support, AclRole.Accountant]);
    return status && role;
  });

  setRule(AclRules.ShowCostPlan, (user: Employee) => {
    return user.showCostPlan;
  });

  setRule<(user: Employee, advanceReport: AdvanceReport) => void>(
    AclRules.AdvanceReportsRevoke,
    (user, advanceReport) => {
      return (
        anyRole([
          AclRole.Accountant,
          AclRole.User,
          AclRole.Director,
          AclRole.ApprovingAgent,
          AclRole.Admin,
          AclRole.Support,
        ]) &&
        advanceReport.employee &&
        advanceReport.employee.portalCode === user.portalCode &&
        (advanceReport.status as AdvanceReportStatus) === AdvanceReportStatus.AwaitingApprovalManager
      );
    },
  );

  setRule<(user: Employee, form: AdvanceReportFormData, isApprovedEarlier: boolean) => boolean>(
    AclRules.AdvanceReportsEditApprovingManager,
    (user, form, isApprovedEarlier) => {
      return anyRole([AclRole.Admin, AclRole.Support]) || !isApprovedEarlier;
    },
  );
}
