
import { defineComponent } from "vue";
import { ElCollapse, ElCollapseItem } from "element-plus";

export default defineComponent({
  name: "ErrorMessage",
  components: {
    ElCollapse,
    ElCollapseItem,
  },
  props: {
    errorCode: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    error: {
      type: String,
      default: "",
    },
  },
});
