export enum FeatureFlag {
  MyRequestTargetPage = "my_request_target_page",
  GRPC_Logger = "grpc_logger",
}

export const useFeature = () => {
  const getFeatureFlagStorageName = (featureFlag: FeatureFlag) => `TN_FA_${featureFlag.toUpperCase()}_FEAT`;

  const featureIsEnable = (featureFlag: FeatureFlag) =>
    localStorage.getItem(getFeatureFlagStorageName(featureFlag)) === "true";

  const enableFeature = (featureFlag: FeatureFlag) =>
    localStorage.setItem(getFeatureFlagStorageName(featureFlag), "true");
  const disableFeature = (featureFlag: FeatureFlag) => localStorage.removeItem(getFeatureFlagStorageName(featureFlag));

  return {
    featureIsEnable,
    enableFeature,
    disableFeature,
  };
};
