
import { defineComponent } from "vue";
import { useAuthActions } from "@/store/modules/auth";
import { showErrorDialog } from "@/shared/helper/error.helper";

export default defineComponent({
  name: "LogOut",
  setup() {
    const { logOut: logOutAction } = useAuthActions();
    const { getAuthUrl } = useAuthActions();
    const logOut = async () => {
      logOutAction();
      try {
        window.location.href = await getAuthUrl();
      } catch (e) {
        void showErrorDialog(e as Error);
      }
    };

    return {
      logOut,
    };
  },
});
