import { RouteType } from "@/router/route.type";
import { RouteRecordRaw } from "vue-router";
import { AclRole } from "@/shared/plugin/acl/acl.type";

export const backofficeRoutes: RouteRecordRaw = {
  path: "/backoffice",
  name: RouteType.Backoffice,
  redirect: {
    name: RouteType.BackofficeDashboard,
  },
  component: () => import("@/views/backoffice/backoffice.vue"),
  meta: {
    title: "Бэкофис",
    access: [AclRole.Admin],
    breadcrumbs: [
      {
        label: "Бэкофис",
        route: { name: RouteType.Backoffice },
      },
    ],
  },
  children: [
    {
      path: "",
      name: RouteType.BackofficeDashboard,
      component: () => import("@/views/backoffice/dashboard/dashboard.vue"),
      meta: {
        title: "Управление справочником",
        access: [AclRole.Admin],
      },
    },
    {
      path: "simple-dict/:type",
      name: RouteType.BackofficeSimpleDict,
      component: () => import("@/views/backoffice/simple-dictionary/simple-dictionary.vue"),
      meta: {
        title: "",
        access: [AclRole.Admin],
        breadcrumbs: [
          {
            label: "Бэкофис",
            route: { name: RouteType.Backoffice },
          },
        ],
      },
      props: true,
    },
    {
      path: "correspondence-dict",
      name: RouteType.BackofficeCorrespondenceDict,
      component: () => import("@/views/backoffice/correspondence-dictionary/correspondence-dictionary.vue"),
      meta: {
        title: "Справочник соответствия ЦФО с ЕКК",
        access: [AclRole.Admin],
        breadcrumbs: [
          {
            label: "Бэкофис",
            route: { name: RouteType.Backoffice },
          },
        ],
      },
      props: true,
    },
    {
      path: "ekk-dict",
      name: RouteType.BackofficeEkkDict,
      component: () => import("@/views/backoffice/ekk-dictionary/ekk-dictionary.vue"),
      meta: {
        title: "Справочник ЕКК и ЦФО",
        access: [AclRole.Admin],
        breadcrumbs: [
          {
            label: "Бэкофис",
            route: { name: RouteType.Backoffice },
          },
        ],
      },
      props: true,
    },
    {
      path: "expense-cost-dict",
      name: RouteType.BackofficeExpenseCostDict,
      component: () => import("@/views/backoffice/expense-cost-dictionary/expense-cost-dictionary.vue"),
      meta: {
        title: "Справочник статьи затрат",
        access: [AclRole.Admin],
        breadcrumbs: [
          {
            label: "Бэкофис",
            route: { name: RouteType.Backoffice },
          },
        ],
      },
      props: true,
    },
    {
      path: "ekk-to-routing-key",
      name: RouteType.BackofficeEkkToRoutingKey,
      component: () => import("@/views/backoffice/ekk-to-routing-key/ekk-to-routing-key.vue"),
      meta: {
        title: "Справочник соответствия ЕКК-Ключ маршрутизации",
        access: [AclRole.Admin],
        breadcrumbs: [
          {
            label: "Бэкофис",
            route: { name: RouteType.Backoffice },
          },
        ],
      },
      props: true,
    },
    {
      path: "ekk-to-org",
      name: RouteType.BackofficeEkkToOrg,
      component: () => import("@/views/backoffice/ekk-to-org/ekk-to-org.vue"),
      meta: {
        title: "Справочник соответствия ЕКК-Ключ организации",
        access: [AclRole.Admin],
        breadcrumbs: [
          {
            label: "Бэкофис",
            route: { name: RouteType.Backoffice },
          },
        ],
      },
      props: true,
    },
    {
      path: "user-info",
      name: RouteType.BackofficeUsersInfo,
      component: () => import("@/views/backoffice/user-info/user-info.vue"),
      meta: {
        title: "Справочник пользователей",
        access: [AclRole.Admin],
        breadcrumbs: [
          {
            label: "Бэкофис",
            route: { name: RouteType.Backoffice },
          },
        ],
      },
    },
    {
      path: "processing-error",
      name: RouteType.BackofficeProcessingError,
      component: () => import("@/views/backoffice/processing-error/processing-error-list.vue"),
      meta: {
        title: "Ошибки работы интеграций",
        access: [AclRole.Admin],
        breadcrumbs: [
          {
            label: "Бэкофис",
            route: { name: RouteType.Backoffice },
          },
        ],
      },
    },
    {
      path: "user-routing-options",
      name: RouteType.BackofficeUserRoutingOptions,
      component: () => import("@/views/backoffice/user-routing-options/user-routing-options.vue"),
      meta: {
        access: [AclRole.Admin],
      },
      children: [
        {
          path: "",
          name: RouteType.BackofficeUserRoutingOptionsList,
          component: () =>
            import("@/views/backoffice/user-routing-options/user-routing-options-list/user-routing-options-list.vue"),
          meta: {
            title: "Справочник маршрутизации",
            access: [AclRole.Admin],
          },
        },
        {
          path: "add-row",
          name: RouteType.BackofficeUserRoutingOptionsAddRow,
          component: () =>
            import(
              "@/views/backoffice/user-routing-options/user-routing-options-create/user-routing-options-create.vue"
            ),
          meta: {
            title: "Добавить позицию",
            access: [AclRole.Admin],
            breadcrumbs: [
              {
                label: "Бэкофис",
                route: { name: RouteType.Backoffice },
              },
              {
                label: "Справочник маршрутизации",
                route: { name: RouteType.BackofficeUserRoutingOptionsList },
              },
            ],
          },
        },
        {
          path: "edit-row/:rowId",
          name: RouteType.BackofficeUserRoutingOptionsEditRow,
          component: () =>
            import("@/views/backoffice/user-routing-options/user-routing-options-edit/user-routing-options-edit.vue"),
          meta: {
            title: "Редактировать строку",
            access: [AclRole.Admin],
            breadcrumbs: [
              {
                label: "Бэкофис",
                route: { name: RouteType.Backoffice },
              },
              {
                label: "Справочник маршрутизации",
                route: { name: RouteType.BackofficeUserRoutingOptionsList },
              },
            ],
          },
          props: true,
        },
      ],
    },
    {
      path: "user-options",
      name: RouteType.BackofficeUserOptions,
      redirect: {
        name: RouteType.BackofficeUserOptionsList,
      },
      component: () => import("@/views/backoffice/user-options/user-options.vue"),
      meta: {
        access: [AclRole.Admin],
      },
      children: [
        {
          path: "",
          name: RouteType.BackofficeUserOptionsList,
          component: () => import("@/views/backoffice/user-options/user-options-list/user-options-list.vue"),
          meta: {
            title: "Настройки пользователей",
            access: [AclRole.Admin],
          },
        },
        {
          path: "add",
          name: RouteType.BackofficeUserOptionsAddRow,
          component: () => import("@/views/backoffice/user-options/user-options-create/user-options-create.vue"),
          meta: {
            title: "Добавить строку",
            access: [AclRole.Admin],
            breadcrumbs: [
              {
                label: "Бэкофис",
                route: { name: RouteType.Backoffice },
              },
              {
                label: "Параметры пользователей",
                route: { name: RouteType.BackofficeUserOptions },
              },
            ],
          },
        },
        {
          path: "edit/:rowId",
          name: RouteType.BackofficeUserOptionsEditRow,
          component: () => import("@/views/backoffice/user-options/user-options-edit/user-options-edit.vue"),
          props: true,
          meta: {
            title: "Редактировать строку",
            access: [AclRole.Admin],
            breadcrumbs: [
              {
                label: "Бэкофис",
                route: { name: RouteType.Backoffice },
              },
              {
                label: "Параметры пользователей",
                route: { name: RouteType.BackofficeUserOptions },
              },
            ],
          },
        },
      ],
    },
  ],
};
