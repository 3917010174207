
import { defineComponent, PropType } from "vue";
import { ElButton } from "element-plus";
import { BaseButtonType } from "@/shared/ui/buttons/base-button/base-button.types";

enum EmitEvents {
  Click = "click",
}

export default defineComponent({
  name: "BaseButton",
  components: { ElButton },
  props: {
    type: {
      type: String as PropType<BaseButtonType>,
      default: BaseButtonType.Primary,
    },
  },
  emits: [EmitEvents.Click],
  setup: () => {
    return {
      EmitEvents,
    };
  },
});
