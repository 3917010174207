import { readonly } from "vue";

export interface AppConfig {
  VUE_APP_YANDEX_METRIKA_ID?: string;
  VUE_APP_TN_ORDER_MINIAPP_URL?: string;
}

export const appConfig: AppConfig = readonly({
  VUE_APP_YANDEX_METRIKA_ID: window?.config?.VUE_APP_YANDEX_METRIKA_ID || process.env.VUE_APP_YANDEX_METRIKA_ID,
  VUE_APP_TN_ORDER_MINIAPP_URL:
    window?.config?.VUE_APP_TN_ORDER_MINIAPP_URL || process.env.VUE_APP_TN_ORDER_MINIAPP_URL,
});
