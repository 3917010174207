import { AdvanceReport } from "@finapp/proto/pkg-ts/common/advance_report_objects";
import { AbsenceLocation } from "@finapp/proto/pkg-ts/common/absence_objects";
import {
  CalculateExpensesSumResponse,
  CreateAdvanceReportMessage,
  ListAdvanceReportsRequest,
  ListAdvanceReportsResponse,
  UpdateAdvanceReportInternalFieldsRequest,
  UpdateAdvanceReportMessage,
} from "@finapp/proto/pkg-ts/advance_report/messages";
import { Entity, RequestStatusMessage } from "@finapp/proto/pkg-ts/common/common";
import { Empty } from "@finapp/proto/pkg-ts/google/protobuf/empty";

export interface AdvanceReportRequest extends AdvanceReport {
  status: AdvanceReportStatus;
}

export interface AdvanceReportsState {
  advanceReport: AdvanceReportRequest;
}

export interface AdvanceReportListItem {
  createdAt?: Date;
  id: string;
  startDate?: Date;
  endDate?: Date;
  locations?: AbsenceLocation[];
  status: AdvanceReportStatus;
  entity: Entity;
  number: string;
}

export enum ExpenseTypes {
  BusinessTrip = "Командировки",
  Other = "Прочее",
}

export enum AdvanceReportStatus {
  AwaitingApprovalManager = "awaiting_approval_manager",
  ApprovedManager = "approved_manager",
  ApprovedAccountant = "approved_accountant",
  NeedChange = "need_change",
  Accepted = "accepted",
  Draft = "draft",
  RevokeForChanging = "revoke_for_changing",
  AwaitingSignAccountant = "awaiting_sign_accountant",
  AwaitingSignEmployee = "awaiting_sign_employee",
  AwaitingDigitalSignEmployee = "awaiting_employee_digital_sign",
  AwaitingPhysicalSignEmployee = "awaiting_employee_physical_sign",
  Signed = "signed",
  DigitallySigned = "digitally_signed",
  PhysicallySigned = "physically_signed",
  ProcessingErrorSign = "processing_error_sign",
  ProcessingError1C = "processing_error_1c",
}

export interface UseAdvanceReportsActions {
  getAdvanceReportById(advanceReportId: string): Promise<void>;
  approveManagerAdvanceReport(advanceReportId: string): Promise<void>;
  approveAccountantAdvanceReport(advanceReportId: string): Promise<void>;
  sendImproveAdvanceReport(advanceReportId: string, comment: string): Promise<void>;
  createAdvanceReport(input: CreateAdvanceReportMessage): Promise<RequestStatusMessage>;
  updateAdvanceReport(input: UpdateAdvanceReportMessage): Promise<RequestStatusMessage>;
  revokeAdvanceReportById(advanceReportId: string): Promise<Empty>;
  calculateExpensesSum(expenses: unknown[]): Promise<CalculateExpensesSumResponse>;
  getAdvanceReportList(request: ListAdvanceReportsRequest): Promise<ListAdvanceReportsResponse>;
  removeAdvanceReportDraft(id: string): Promise<Empty>;
  startAdvanceReportPhysicalSignification(id: string): Promise<Empty>;
  markAdvanceReportPhysicallySigned(id: string): Promise<Empty>;
  updateAdvanceReportInternalFields(request: UpdateAdvanceReportInternalFieldsRequest): Promise<Empty>;
}

export interface UseAdvanceReportsState {
  state: AdvanceReportsState;
  setAdvanceReport(report: AdvanceReportRequest): void;
  clearAdvanceReport(): void;
}
